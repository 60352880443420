import * as React from 'react'
import { graphql } from 'gatsby'
import { Link } from "gatsby";
import RichText from '../../components/richText';
import { GatsbyImage } from "gatsby-plugin-image"
import { Waypoint } from 'react-waypoint';

export const CaseStudies = ({ slice }) => {

  //Waypoints

  const handleEnterCase = (index) => {

    document.getElementById(`dy-cases__item-${index}`).classList.add('is-visible')

  }

  const handleLeaveCase = (index) => {

    document.getElementById(`dy-cases__item-${index}`).classList.remove('is-visible')

  }

  //DAta
  const $casesList = slice.items;


  return (

    $casesList.length > 0 &&
    <section className="c-cases" aria-label={slice.primary.cases_kicker}>
      <div className="c-cases__heading c-heading o-wrapper">
        <h2 className="c-heading__kicker">{slice.primary.cases_kicker}</h2>
        <div className="c-heading__heading">
          <RichText render={slice.primary.cases_title.richText} />
        </div>
        {slice.primary.cases_link.url && <Link to={slice.primary.cases_link.url} className="c-cases__view-all c-btn c-btn--icon">{slice.primary.cases_linktitle}<span className="c-btn__icon" /></Link>}
      </div> {/* .c-what__heading */}


      <ul id="js-cases__list" className="c-cases__list">
        {$casesList.filter(c => !c.case.isBroken).map((item, i) => {

          let data = item.case.document.data;

          return (

            <li key={i} id={`dy-cases__item-${i}`} className="c-cases__item js-cases__item">
              <Waypoint
                onEnter={() => {
                  handleEnterCase(i)
                }}
                onLeave={() => {
                  handleLeaveCase(i)
                }}
                // debug="true"
                bottomOffset="35%">
                <div>
                  {data.case_color && <div className="c-case__bg c-case__bg--desktop" style={{ backgroundColor: `#` + data.case_color }} />}
                  <article className="c-case js-case">
                    <div className="c-case__image">
                      <div className="c-case__bg c-case__bg--mobile" style={{ backgroundColor: `#` + data.case_color }} />
                      {data.case_image && data.case_image.url &&
                        <GatsbyImage className="c-case__pic c-pic" image={data.case_image.gatsbyImageData} />
                      }
                    </div> {/* .c-case__image */}
                    <div className="c-case__info o-wrapper">
                      <h2 className="c-case__title c-case__transition u-alt-font--bold"><Link to={`${item.case.document.url}`} className="u-tap-size" tabIndex={-1}>{data.case_title}</Link></h2>
                      {data.case_filters.length > 0 &&
                        <ul className="c-case__services c-case__transition u-alt-font--bold">
                          {data.case_filters.map((filter, i) => (
                            filter.case_filter.document && <li key={i} className="c-case__service"><Link to={`/work?filter=` + filter.case_filter.document.uid} className="c-case__service-link u-tap-size">{filter.case_filter.document.data.filter_name}</Link></li>
                          ))}
                        </ul>
                      }
                      {data.case_description &&
                        <div className="c-case__description c-case__transition u-text">
                          <RichText render={data.case_description.richText} />
                        </div>
                      }
                      <Link to={`${item.case.document.url}`} className="c-case__link c-case__transition c-link u-tap-size">{data.case_linktitle}<span className="c-btn__icon" /></Link>
                    </div> {/* .c-case__info */}
                  </article> {/* .c-case */}
                </div>
              </Waypoint>
            </li>

          )
        })}

      </ul>

    </section >
  )

}

export const query = graphql`
  fragment PostDataBodyCaseStudies on PrismicHomeDataBodyCaseStudies {
          items {
            case {
              uid
              isBroken
              document {
                ... on PrismicCase {
                  url
                  data {
                    case_color
                    case_description {
                      richText
                    }
                    case_image {
                      url
                        gatsbyImageData(
                          
                          breakpoints: [414,767,768,980,1024,1440,1680,1920]
                        )

                    }
                    case_linktitle
                    case_filters {
                      case_filter {
                        document {
                          ... on PrismicFilter {
                            data {
                              filter_name
                            }
                            uid
                          }
                        }
                      }
                    }
                    case_title
                  }
                }
              }
            }
          }
          primary {
            cases_kicker
            cases_linktitle
            cases_title {
              richText
            }
            cases_link{
              url
            }
          }
    
    
  }
  
`
