import React from "react"
import { graphql } from 'gatsby'
import { Link } from "gatsby";
import RichText from '../../components/richText';
import ImgResolver from '../../utils/imgResolver'

export const Testimonies = ({ slice }) => {

  const $testimonialsList = slice.items;

  return (

    $testimonialsList.length > 0 &&
    <section className="c-quotes o-section" aria-label={slice.primary.testimonies_kicker ? slice.primary.testimonies_kicker : slice.primary.testimonies_title.text}>
      <div className="c-quotes__heading c-heading c-heading--center o-wrapper">
        <h2 className="c-heading__kicker">{slice.primary.testimonies_kicker ? slice.primary.testimonies_kicker : ''}</h2>
        <div className="c-heading__heading">
          <RichText render={slice.primary.testimonies_title.richText} />
        </div>
      </div>

      <div id="js-quotes__carousel" className="c-quotes__carousel js-carousel__notInit">
        <ul className="c-quotes__list js-carousel">
          {$testimonialsList.map((item, i) => {

            let data = item.testimony.document.data;
            let client = data.body ? data.body[0] : ''

            return (
              <li key={i} className={`c-quotes__item c-quote ${i !== 0 ? 'js-carousel__notInit--notInitHide' : ''}`}>
                <div className="c-quote__holder o-wrapper">

                  <div className="c-quote__quote-holder">
                    <blockquote className="c-quote__quote u-text">
                      {data.testimony_quote && <RichText render={data.testimony_quote.richText} />}
                      {data.testimony_content && <RichText render={data.testimony_content.richText} />}
                    </blockquote> {/* .c-quote__quote */}

                    {data.testimony_link && data.testimony_link.url &&
                    <div className="c-quote__link-holder c-quote__link-holder@md">
                      <Link to={data.testimony_link.url} target="_blank" rel="noopener" className="c-quote__link c-link u-tap-size">{data.testimony_linktitle}<span className="c-link__icon" /></Link>
                    </div>
                  }

                  </div> {/* .c-quote__quote-holder */}
                  {client &&
                    <div className="c-quote__client c-quote-client">
                      <div className="c-quote-client__logo">
                        {client.primary.client_logo.url && client.primary.client_name &&
                          ImgResolver(client.primary.client_logo, client.primary.client_company, 'c-quote-client__img')
                        }
                      </div> {/* .c-quote-client__logo */}
                      <cite className="c-quote-client__text">
                        {client.primary.client_name && <strong className="c-quote-client__name u-alt-font--bold">{client.primary.client_name}</strong>}
                        {client.primary.client_job && <span className="c-quote-client__job u-alt-font">{client.primary.client_job}</span>}
                        <img src="assets/images/static/quotes/stars-5-blue.svg" width={150} height={26} alt="5 stars" title="5 stars" className="c-quote-client__stars" loading="lazy" />
                      </cite> {/* .c-quote-client__text */}
                    </div>
                  }

                  {data.testimony_link && data.testimony_link.url &&
                    <div className="c-quote__link-holder c-quote__link-holder@xs">
                      <Link to={data.testimony_link.url} target="_blank" rel="noopener" className="c-quote__link c-link u-tap-size">{data.testimony_linktitle}<span className="c-link__icon" /></Link>
                    </div>
                  }
                </div> {/* .c-quote__holder */}
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )

}


export const query = graphql`
  fragment PostDataBodyTestimonies on PrismicHomeDataBodyTestimonies {
    primary {
      testimonies_kicker
      testimonies_title {
        richText
        text
      }
    }
    items {
        testimony {
          document {
            ... on PrismicTestimony {
              data {
                testimony_quote {
                  richText
                }
                testimony_linktitle
                testimony_link {
                  url
                }
                testimony_content {
                  richText
                }
                body {
                  ... on PrismicTestimonyDataBodyClient {
                    primary {
                      client_company
                      client_job
                      client_name
                      client_logo {
                        url
                        dimensions{
                          width
                          height
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
  }
`