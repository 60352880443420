import React from "react"

export const ImgResolver = ( image, title, className ) => {

      let isSVG = image.url.indexOf('.svg') != -1 ? true : false;

      let width = isSVG ? image.dimensions.width : image.dimensions.width / 2;
      let height = isSVG ? image.dimensions.height : image.dimensions.height / 2;

      //style={{ aspectRatio: image.dimensions.height * 100 / image.dimensions.width }}
      return <img src={image.url} width={width} height={height} title={title} alt={title} className={className} loading="lazy" />
}

export default ImgResolver;