
import React, { useEffect, useState } from "react"
import { graphql } from 'gatsby'
import Layout from '../components/layout';
import Seo from '../components/seo';
import RichText from '../components/richText';
import Helmet from "react-helmet"
import { withPrefix } from 'gatsby';
import { Link } from "gatsby";
import { SliceZone } from '@prismicio/react'
import { components } from '../slices/home'


const Home = ({ data }) => {

  const [hasMounted, setHasMounted] = React.useState(false);

  useEffect(() => {
    setHasMounted(true);
    document.querySelector('html').classList.add('smooth');

    // VIDEO POPUP
    var vPopupTimer = null;
    let $vPopup = document.getElementById('js-video-popup');
    let $vPopupVideo = $vPopup.querySelector('.js-video-popup__video');
    function showVideoPopup(){
        clearTimeout(vPopupTimer);
        // Show popup
        $vPopup.classList.add('is-active');
        // Auto play video
        $vPopupVideo.play();
    }
    function hideVideoPopup(){
        $vPopup.classList.add('is-closing');
        vPopupTimer = setTimeout( () => {
            $vPopup.classList.remove('is-active', 'is-closing');
            $vPopupVideo.pause();
            $vPopupVideo.currentTime = 0;
        }, 650)
    }

    document.querySelectorAll('.js-showVideoPopup').forEach( $el => {
      $el.onclick = () => {
        showVideoPopup();
      }
    })
    document.querySelectorAll('.js-hideVideoPopup').forEach( $el => {
      $el.onclick = () => {
        hideVideoPopup();
      }
    })

  }, []);

  //Patch data
  if (!data) return null

  const $metaData = data.prismicMetadata.data;
  const $cta = data.prismicCalltoaction ? data.prismicCalltoaction.data : null;
  const $footer = data.prismicFooter.data;
  const $socials = data.prismicSocials ? data.prismicSocials.data : null;
  const $contactUs = data.prismicContactUs ? data.prismicContactUs.data : null;

  const $home = data.prismicHome.data.body;
  const $hero = $home[0];
  const $clutch = data.prismicClutch ? data.prismicClutch.data : null;

  return (

    <Layout cta={$cta} footer={$footer} clutch={$clutch} socials={$socials} contactUs={$contactUs} headerTransparent={true}>
      
      <Seo metadata={$metaData}></Seo>

      <Helmet>

        {/* No inline CSS */}
        <link href={withPrefix('../../assets/css/sitio/tiny-slider.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/tiny-slider-custom.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/home.partners.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/home.what.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/home.cases.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/components.quote.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/home.testimonials.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/home.awards.css')} rel="stylesheet" type="text/css" />
        <link href={withPrefix('../../assets/css/sitio/home.video-popup.css')} rel="stylesheet" type="text/css" />

        {hasMounted && <script src="../../assets/js/sitio/home.js" />}

      </Helmet>


      <div className="c-home-hero">
        <div className="c-home-hero__holder">
          <div className="c-home-hero__wrapper o-wrapper o-section">
            <div className="c-home-hero__tagline">
              <RichText render={$hero.primary.title.richText} />
              {$hero.primary.description && <RichText render={$hero.primary.description.richText} />}
            </div> {/* .c-home-hero__tagline */}
            {$hero.items && $hero.items.length > 0 &&
              <>
                <div className="c-home-hero__btns">
                  {$hero.items.map((item, i) => (
                    item.link.url &&
                    <Link key={i} to={item.link.url}  className={i === 0 ? `c-home-hero__btn c-btn c-btn--cta c-btn--ih u-tap-size` : `c-home-hero__btn c-btn c-btn--ih c-btn--stroke c-btn--white u-tap-size`} target={i !== 0 ? "_blank" : ""} rel={i !== 0 ? "noopener" : ""}>{item.linktitle}</Link>
                  ))}

                  {$hero.primary.video && $hero.primary.video.url && $hero.primary.video_text && <button type="button" className={'js-showVideoPopup c-home-hero__btn c-btn c-btn--ih c-btn--stroke c-btn--white u-tap-size'}>{$hero.primary.video_text}</button>}

                </div> {/* .c-home-hero__btns */}
              </>
            }
          </div> {/* .c-home-hero__wrapper */}
        </div> {/* .c-home-hero__holder */}
        {$clutch && 
          <div className="c-home-hero__clutch c-home-hero-clutch">
            <div className="c-home-hero-clutch__mask">
              <div className="c-home-hero-clutch__content">
                <p className="c-home-hero-clutch__text">{$clutch.clutch_title} <span className="u-hidden-for-seo">Clutch</span></p>
                <img src="assets/images/static/clutch/clutch.svg" width={196} height={57} className="c-home-hero-clutch__logo" alt="Clutch" title="Clutch" />
                <div className="c-home-hero-clutch__bottom">
                  <img src="assets/images/static/clutch/clutch-stars.svg" width={150} height={26} className="c-home-hero-clutch__stars" alt="5 stars" title="5 stars" />
                  {$clutch.clutch_link.url && <a href={$clutch.clutch_link.url} className="c-home-hero-clutch__link u-alt-font u-tap-size" target="_blank"  rel="noopener noreferrer">{$clutch.clutch_linktitle}</a>}
                </div> {/* .c-home-hero-clutch__bottom */}
              </div> {/* .c-home-hero-clutch__content */}
            </div> {/* .c-home-hero-clutch__mask */}
          </div>
        }
        <svg className="c-home-hero__mask--xs" enableBackground="new 0 0 768 200" width={0} height={0} viewBox="0 0 768 200" xmlns="http://www.w3.org/2000/svg"><defs><clipPath id="c-home-hero__mask-xs"><path d="m383.95 54.25c-139.2 0-270.06-19.66-383.95-54.22v145.75c113.89 34.56 244.75 54.22 383.95 54.22 139.24 0 270.14-19.67 384.05-54.25v-145.75c-113.91 34.58-244.81 54.25-384.05 54.25z" /></clipPath></defs></svg>
        <svg className="c-home-hero__mask--xl" enableBackground="new 0 0 1920 58" height={58} viewBox="0 0 1920 58" width={1920} xmlns="http://www.w3.org/2000/svg"><path d="m1920 0c-284.77 30.31-612.03 47.56-960.12 47.56-348 0-675.16-17.24-959.88-47.53v57.97h1920z" fill="#fff" /></svg>
      </div>


      <main>

        <SliceZone slices={$home} components={components} />

      </main>

      
      {/* VIDEO POPUP */}

      {$hero.primary.video && $hero.primary.video.url &&
      <div id="js-video-popup" className="c-video-popup">
        <div className="c-video-popup__overlay js-hideVideoPopup"></div>
        <div className="c-video-popup__popup">
          <button type="button" aria-label="Close video" title="Close video" className="c-video-popup__close js-hideVideoPopup"></button>
          <div className="js-video-popup__holder">
            <video width={1680} height={945} preload controls className="js-video-popup__video">
              <source src={$hero.primary.video.url} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    }


    </Layout>

  )
}

export const query = graphql`
  query HomeQuery {
    prismicHome {
      data {
        body {
          ... on PrismicHomeDataBodyHero {
            primary {
              description {
                richText
              }
              title {
                richText
              }
              video_text
              video {
                url
              }
            }
            items {
              linktitle
              link {
                url
              }
            }
          }
          ... on PrismicHomeDataBodyPartners {
           slice_type
          }
          ...PostDataBodyPartners
          ... on PrismicHomeDataBodyServices {
            slice_type
          }
          ...PostDataBodyServices
          ... on PrismicHomeDataBodyCaseStudies {
            slice_type
          }
          ...PostDataBodyCaseStudies
          ... on PrismicHomeDataBodyTestimonies {
            slice_type
          }
          ...PostDataBodyTestimonies
          ... on PrismicHomeDataBodyAwards {
           slice_type
          }
          ...PostDataBodyAwards
        }
      }
    },
    prismicMetadata {
      ...MetadataFragment
    },   
    prismicClutch {
      ...ClutchFragment
    },
    prismicFooter{
      ...FooterFragment   
    }
    prismicCalltoaction{
      ...CallToActionFragment  
    }
    prismicSocials{
      ...SocialsFragment   
    }
    prismicContactUs{
      ...ContactUsFragment   
    }
    
  }
`

export default Home
