import * as React from 'react'
import { graphql } from 'gatsby'
import { Link } from "gatsby";
import RichText from '../../components/richText';
import ImgResolver from '../../utils/imgResolver'

export const Services = ({ slice }) => {

      return (

            <section className="c-what o-section o-wrapper o-wrapper--xl" aria-label={slice.primary.services_kicker}>
                  <div className="c-what__heading c-heading c-heading--center">
                        <h2 className="c-heading__kicker">{slice.primary.services_kicker}</h2>
                        <div className="c-heading__heading">
                              <RichText render={slice.primary.services_title.richText} />
                        </div>
                        {slice.primary.services_description && 
                        <div className="c-heading__description u-text">
                              <p>{slice.primary.services_description}</p>
                        </div>}
                  </div>
                  {slice.items.length > 0 &&
                  <ul className="c-what__list">
                        {slice.items.map((item, i) => (
                              <li key={i} className="c-what__item">
                                    {item.service_image && item.service_image.url && 
                                           ImgResolver(item.service_image, item.service_name, 'c-what__icon')
                                    }
                                    <h3 className="c-what__name u-alt-font--bold">{item.service_name}</h3>
                                    {item.service_description && <p className="c-what__text">{item.service_description}</p>}
                              </li>
                        ))}
                  </ul>}
                  {slice.primary.service_link && slice.primary.service_link.url && <Link to={slice.primary.service_link.url} className="c-what__link c-btn c-btn--stroke c-btn--icon">{slice.primary.service_linktitle}<span className="c-btn__icon"/></Link>}
            </section>
      )

}


export const query = graphql`
  fragment PostDataBodyServices on PrismicHomeDataBodyServices {
      items {
            service_description
            service_image {
              url
              dimensions{
                    width
                    height
              }
            }
            service_name
          }
          primary {
            services_description
            services_kicker
            service_linktitle
            services_title {
              richText
            }
            service_link {
              url
            }
          }
  }
`