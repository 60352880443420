import { Partners } from './partners';
import { Services } from './services';
import { CaseStudies } from './caseStudies';
import { Testimonies } from './testimonies';
import { Awards } from './awards';

export const components = {
  partners: Partners,
  services: Services,
  case_studies: CaseStudies,
  testimonies: Testimonies,
  awards: Awards,
  
}