import * as React from 'react'
import { graphql } from 'gatsby'
import ImgResolver from '../../utils/imgResolver'

export const Partners = ({ slice }) => {

      return (

            slice.items.length > 0 && 
            <section className="c-partners o-wrapper" aria-label={slice.primary.partnerstitle}>
                  <h2 className="u-hidden-for-seo">{slice.primary.partnerstitle}</h2>
                  <ul className="c-partners__list">
                        {slice.items.map((item, i) => (
                        item.image && item.image.url && 
                        <li key={i} className="c-partners__item">
                              {ImgResolver(item.image, item.name, 'c-partners__img')}
                        </li> 
                        ))}
                  </ul> {/* .c-partners__list */}
            </section>
      )

}


export const query = graphql`
  fragment PostDataBodyPartners on PrismicHomeDataBodyPartners {
      items {
      name
      image {
            dimensions {
                  height
                  width
                }
            url
      }
      }
      primary {
      partnerstitle
      }
  }
`