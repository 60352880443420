import * as React from 'react'
import { graphql } from 'gatsby'
import RichText from '../../components/richText';
import ImgResolver from '../../utils/imgResolver'

export const Awards = ({ slice }) => {
  return (

    slice.items && slice.items.length > 0 &&

    <section className="c-awards o-section" aria-label={slice.primary.awards_title.text}>
      <div className="c-awards__wrapper o-wrapper">
        <div className="c-awards__heading c-heading c-heading--center">
          <div className="c-heading__heading">
            <RichText render={slice.primary.awards_title.richText} />
          </div> {/* .c-heading__heading */}
          {slice.primary.awards_description &&
            <div className="c-heading__description u-text">
              <RichText render={slice.primary.awards_description.richText} />
            </div>
          }
        </div> {/* .c-what__heading */}
        <div id="js-awards__carousel" className="c-awards__carousel js-carousel__notInit">
          <ol className="c-awards__list js-carousel">
            {slice.items.map((item, i) => (
              <li key={i} className={`c-awards__item c-award u-alt-font ${i !== 0 ? 'js-carousel__notInit--notInitHide' : ''}`}>

                {item.award_link && item.award_link.url && 

<a href={item.award_link.url} target="_blank" class="c-award__link">
                    {item.award_image && item.award_image.url && 
                      ImgResolver(item.award_image, item.award_name, 'c-award__badge')
                    }
                  </a>                  
                }

                {!item.award_link.url &&  
                    item.award_image && item.award_image.url && 
                      ImgResolver(item.award_image, item.award_name, 'c-award__badge')
                }

                {item.award_name && <h3 className="c-award__title">{item.award_name}</h3>}
                {item.award_description.richText && 
                  <div className="c-award__description">
                    <RichText render={item.award_description.richText} />
                  </div>
                }
              </li>
            ))}
          </ol> {/* .c-awards__list */}
        </div> {/* .c-awards__carousel */}
      </div> {/* .c-awards__wrapper */}
    </section>
  )

}


export const query = graphql`
  fragment PostDataBodyAwards on PrismicHomeDataBodyAwards {
    primary {
      awards_description {
        richText
      }
      awards_title {
        richText
        text
      }
    }
    items {
      award_description {
        richText
      }
      award_image {
        url
        dimensions{
          width
          height
        }
      }
      award_link{
        url
      }
      award_name
    }
  }
`